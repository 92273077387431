import React from 'react';

const Arrow = (props) => (
  <svg viewBox="0 0 14 14" fill="currentColor" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 12.524L10.524 2H0V0h14v14h-2V3.476L1.476 14 0 12.524z"
    />
  </svg>
);

export default Arrow;
