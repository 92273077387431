import { keyframes } from 'styled-components';

export const fadeFromCorner = keyframes`
 0% {
    transform: translate(-100%, 100%);
    transform-origin: bottom left;
    opacity: 0;
  }
  33% {
    transform: translate(0, 0);
    opacity: 1;
  }
`;

export const scrollUp = keyframes`
 0% {
    transform:  translateY(100%);
  }
  100% {
    transform: translateY(-100%);
  }
`;

export const verticalSlide = keyframes`
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0%);
  }
`;
