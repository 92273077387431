import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import IntLink from './IntLink';
import Arrow from '../icons/Arrow';
import { fadeFromCorner } from '../../utils/keyframes';
import { P } from './Typography';
import { duration } from '../../utils/transitions';

const ArrowIcon = styled(Arrow)`
  width: 100%;
`;

const Wrapper = styled(P)`
  justify-self: start;
  text-transform: uppercase;
  cursor: pointer;
  display: inline-grid;
  grid-template-columns: ${({ arrow }) => (arrow ? 'auto 0.66em' : 'auto')};
  grid-column-gap: 8px;
  align-items: center;
  line-height: 1.333333333em;
  &:hover ${ArrowIcon} {
    animation: ${fadeFromCorner} ${duration * 2}s ease infinite both;
  }
`;

const Text = styled.span`
  position: relative;
  white-space: nowrap;
`;

const ArrowWrapper = styled.div`
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const getElement = (href, onClick, to) => {
  if (href) return 'a';
  if (onClick) return 'button';
  if (to) return IntLink;
  return 'div';
};

export default function ArrowLink({
  className,
  children,
  href,
  onClick,
  target,
  type,
  to,
  arrow,
  ...props
}) {
  return (
    <Wrapper
      as={getElement(href, onClick, to)}
      className={className}
      href={href}
      onClick={onClick}
      target={target}
      to={to}
      type={type}
      arrow={arrow}
      {...props}
    >
      <Text>{children}</Text>
      {arrow && (
        <ArrowWrapper>
          <ArrowIcon />
        </ArrowWrapper>
      )}
    </Wrapper>
  );
}

ArrowLink.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  href: PropTypes.string,
  onClick: PropTypes.func,
  target: PropTypes.string,
  to: PropTypes.string,
  type: PropTypes.string,
  arrow: PropTypes.string,
};

ArrowLink.defaultProps = {
  arrow: true,
};
