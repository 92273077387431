import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Grid from '../../common/Grid';
import { H2, P } from '../../common/Typography';
import Button from '../../common/Button';
import { maxWidth, minWidth } from '../../../utils/breakpoints';

const Wrapper = styled(Grid)`
  min-height: 80vh;
  align-items: center;
  ${maxWidth('tabletPortrait')} {
    background: url('https://i.giphy.com/media/A8nLow7DRwluU/giphy.webp')
      no-repeat center/ cover;
  }
  ${minWidth('tabletPortrait')} {
    background: url('https://i.giphy.com/media/gKsJUddjnpPG0/giphy.webp')
      no-repeat center/ cover;
  }
`;

const Inner = styled.div`
  grid-column: col / span 12;
  text-align: center;
  display: grid;
  justify-items: center;
  grid-row-gap: inherit;
`;

export default function Centerpiece({ className, title, text, ctaText }) {
  return (
    <Wrapper className={className} paddedTop paddedBottom>
      <Inner>
        <H2>{title}</H2>
        <P>{text}</P>
        <Button to="/" color="white">
          {ctaText}
        </Button>
      </Inner>
    </Wrapper>
  );
}

Centerpiece.propTypes = {
  className: PropTypes.string,
  ctaText: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};
