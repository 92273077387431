import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import SEO from '../components/common/Seo';
import localize from '../components/common/localize';
import Centerpiece from '../components/pages/404/Centerpiece';

function NotFound({ data }) {
  const { page } = data;
  return (
    <>
      <SEO title={page.title} description={page.description} />
      <Centerpiece title={page.title} ctaText={page.ctaText} text={page.text} />
    </>
  );
}

export default localize(NotFound);

export const query = graphql`
  query {
    page: sanityFourOhFour {
      title {
        localized
      }
      description {
        localized
      }
      text {
        localized
      }
      ctaText {
        localized
      }
    }
  }
`;

NotFound.propTypes = {
  data: PropTypes.object.isRequired,
};
