import styled from 'styled-components';
import styledMap from 'styled-map';

import ArrowLink from './ArrowLink';
import { duration } from '../../utils/transitions';

const Button = styled(ArrowLink)`
  justify-self: auto;
  color: ${styledMap('color', {
    black: ({ theme }) => theme.colors.black,
    white: ({ theme }) => theme.colors.white,
    maroon: ({ theme }) => theme.colors.darkMaroon,
  })};
  appearance: none;
  text-align: center;
  border-width: 1px;
  border-style: solid;
  border-color: ${styledMap('color', {
    black: ({ theme }) => theme.colors.black,
    white: ({ theme }) => theme.colors.white,
    maroon: ({ theme }) => theme.colors.darkMaroon,
  })};
  position: relative;
  overflow: hidden;
  padding: 32px 48px;
  transition: color ${duration / 2}s ease, background ${duration / 2}s ease;
  background: transparent;
  &:hover {
    color: ${styledMap('color', {
      black: ({ theme }) => theme.colors.white,
      white: ({ theme }) => theme.colors.black,
      maroon: ({ theme }) => theme.colors.white,
    })};
    background: ${styledMap('color', {
      white: ({ theme }) => theme.colors.white,
      black: ({ theme }) => theme.colors.black,
      maroon: ({ theme }) => theme.colors.darkMaroon,
    })};
  }
`;

export default Button;
